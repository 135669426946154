/* eslint-disable no-unexpected-multiline */
/* eslint-disable no-unused-vars */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { renderBlocks } from "@src/templates/page/utils"
import Layout from "@src/layout"

const FlexiblePage = (props) => {
  const {
    children,
    data: {
      page: { id, __typename, title, meta_data }
    }
  } = props

  let { alert, blocks } = props.data ? props.data.page : props

  blocks = Array.isArray(blocks) ? blocks : []

  blocks = blocks.map((block) => {
    if (block.__typename === "ContentfulJumbotron") {
      block.currentPath = props.location.pathname
      return block
    }

    if (block.__typename !== "ContentfulContainer") {
      return block
    }

    block.blocks = (block?.blocks || []).map((childBlock, index) => {
      childBlock.containerTheme = block.theme
      childBlock.rowIndex = index
      ;(childBlock?.blocks || []).map((childBlockChildren, index) => {
        childBlockChildren.currentIndex = index + 1

        if (childBlockChildren.__typename === "ContentfulCarousel") {
          childBlockChildren.slides.map((slide) => {
            slide.carouselTheme = childBlockChildren.theme
          })
        }

        if (childBlockChildren.__typename !== "ContentfulColumn") {
          return childBlockChildren
        }

        // prettier-ignore
        (childBlockChildren.blocks || []).map((childBlockChildrenChildren, index) => {
          if (childBlockChildren.__typename !== "ContentfulRow") {
            if (childBlockChildrenChildren.blocks) {
              childBlockChildrenChildren.blocks.map((rowItems, index) => {
                rowItems.currentIndex = index + 1
              })
            }
          }
        })

        return childBlockChildren
      })

      return childBlock
    })

    return block
  })

  const owner = {
    id: id,
    __typename: __typename
  }

  return (
    <Layout title={title} meta={meta_data} alert={alert} locationPath={props.location.pathname}>
      {children}
      {renderBlocks(blocks, owner)}
    </Layout>
  )
}

FlexiblePage.propTypes = {
  /**
   * Components to inject before the page content.
   */
  children: PropTypes.node,
  /**
   * An array of components that get mapped to the <Block /> component.
   */
  blocks: PropTypes.arrayOf(PropTypes.object)
}

FlexiblePage.defaultProps = {
  containers: []
}

export const query = graphql`
  fragment Block on Node {
    ...Button
    ...Card
    ...Carousel
    ...ContentBlock
    ...Form
    ...Icon
    ...Image
    ...MediaObject
    ...RichContentBlock
    ...Row
    ...Snippet
    ...Video
    ...CollapsibleAttributes
  }

  fragment Carousel on ContentfulCarousel {
    __typename
    body {
      childMarkdownRemark {
        html
      }
    }
    button_link {
      ... on ContentfulButton {
        __typename
        ...Button
      }
    }
    slides {
      __typename
      ... on ContentfulCard {
        ...Card
      }
      __typename
      ... on ContentfulImage {
        ...Image
      }
      __typename
      ... on ContentfulAuthor {
        ...Author
      }
      __typename
      ... on ContentfulRow {
        ...Row
        blocks {
          __typename
          ... on ContentfulImage {
            ...Image
          }
          ... on ContentfulContentBlock {
            __typename
            ...ContentBlock
          }
          ... on ContentfulColumn {
            __typename
            ...Column
            blocks {
              ... on ContentfulButton {
                __typename
                ...Button
              }
              ... on ContentfulContentBlock {
                __typename
                ...ContentBlock
              }
            }
          }
        }
      }
    }
    theme
    title
  }

  fragment Form on ContentfulForm {
    __typename
    title
    url
    redirect_url
    conversion_label
    align
    body {
      childMarkdownRemark {
        html
      }
    }
    footer {
      childMarkdownRemark {
        html
      }
    }
    formFields {
      __typename
      internal {
        content
      }
    }
  }

  fragment Image on ContentfulImage {
    src {
      gatsbyImageData
      title
    }
    theme
    image_logo_train: src {
      gatsbyImageData(width: 352, quality: 100)
      title
    }
  }

  fragment Card on ContentfulCard {
    __typename
    id
    heading: title
    body {
      childMarkdownRemark {
        html
      }
    }
    image {
      gatsbyImageData
      title
    }
    image_background: image {
      gatsbyImageData(width: 344)
      title
    }
    image_media: image {
      gatsbyImageData(width: 310, layout: FIXED)
      title
    }
    image_chyron: image {
      gatsbyImageData
      title
    }
    button_label
    url
    theme
  }

  fragment ContentBlock on ContentfulContentBlock {
    __typename
    id
    body {
      childMarkdownRemark {
        html
      }
    }
    margin_bottom
  }

  fragment Icon on ContentfulIcon {
    __typename
    name
    heading
    description
    theme
    statistics {
      childMarkdownRemark {
        html
      }
    }
  }

  fragment Jumbotron on ContentfulJumbotron {
    __typename
    id
    heading: title
    theme
    homepage
    overlay
    background_image {
      gatsbyImageData(quality: 80)
      title
    }
    blocks {
      ...Block
      ... on ContentfulContainer {
        ...Container
        __typename
        blocks {
          ...Block
          ... on ContentfulRow {
            ...Row
            blocks {
              ...Block
              ... on ContentfulColumn {
                ...Column
                blocks {
                  ...Block
                }
              }
            }
          }
        }
      }
    }
  }

  fragment MediaObject on ContentfulMediaObject {
    __typename
    heading: title
    label
    image_orientation
    url
    image {
      gatsbyImageData(width: 94, height: 66)
      title
    }
  }

  fragment ProgressTrackerEntry on ContentfulProgressTrackerEntry {
    __typename
    label
    heading
    body {
      childMarkdownRemark {
        html
      }
    }
    image {
      gatsbyImageData
    }
    button {
      ...Button
    }
  }

  fragment ProgressTracker on ContentfulProgressTracker {
    __typename
    id
    entries {
      ...RichContentBlock
    }
  }

  fragment RichContentBlock on ContentfulRichContentBlock {
    primary_blocks {
      ...ContentBlock
      ...Button
    }
    secondary_blocks {
      ...ContentBlock
      ...Button
    }
    image {
      gatsbyImageData
      title
    }
    image_alignment
    theme
    heading
    label
  }

  fragment Video on ContentfulVideo {
    __typename
    description
    image {
      gatsbyImageData
      title
    }
    url
    theme
  }

  fragment Author on ContentfulAuthor {
    __typename
    bio {
      childMarkdownRemark {
        excerpt(pruneLength: 220)
        html
        rawMarkdownBody
      }
    }
    category {
      title
      slug
    }
    id
    image {
      gatsbyImageData(width: 140, height: 140)
      title
    }
    name
    slug
    sme
    title
  }

  # -----------------------------------------------------

  fragment Container on ContentfulContainer {
    __typename
    id
    type
    theme
    padding
    background_image {
      gatsbyImageData
      title
    }
  }

  fragment Row on ContentfulRow {
    __typename
    alignment
    centered
    gutter
    layout: layout_is_always
    margin_bottom
  }

  fragment Column on ContentfulColumn {
    __typename
    blocks {
      __typename
      ... on ContentfulImage {
        ...Image
      }
    }
  }

  # -----------------------------------------------------

  query PageQuery($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      __typename
      id
      slug
      title
      meta_data {
        name
        content
      }
      blocks {
        __typename
        ...Block
        ...Jumbotron
        ...ProgressTracker
        ... on ContentfulContainer {
          ...Container
          blocks {
            __typename
            ...Block
            ... on ContentfulRow {
              ...Row
              blocks {
                __typename
                ...Block
                ... on ContentfulColumn {
                  ...Column
                  blocks {
                    ...Block
                    ... on ContentfulRow {
                      ...Row
                      blocks {
                        __typename
                        ...Block
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      alert {
        body
        theme
        title
        image {
          gatsbyImageData
        }
        image_modal: image {
          gatsbyImageData(width: 452, height: 288)
          title
        }
        image_modal_small: image {
          gatsbyImageData(height: 186, aspectRatio: 1.6, quality: 100, outputPixelDensities: [2, 3])
          title
        }
        button {
          ...Button
        }
      }
    }
  }
`

export default FlexiblePage
