import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "@components/button"
import SVG from "@components/svg"

import {
  body,
  button,
  close,
  content,
  image,
  is_open,
  modal_inner,
  modal,
  theme_bar,
  theme_modal_center,
  theme_modal_bottom_left,
  theme_modal_bottom_right,
  theme_post_sticky_modal_alert,
  theme_navigation
} from "./styles.module.scss"

const themeOptions = {
  "bar-top": theme_bar,
  "modal-center": theme_modal_center,
  "modal-bottom-left": theme_modal_bottom_left,
  "modal-bottom-right": theme_modal_bottom_right,
  "post-sticky-modal-alert": theme_post_sticky_modal_alert,
  navigation: theme_navigation
}

const Alert = (props) => {
  let {
    body: $body,
    button: $button,
    image_modal_small,
    image_modal,
    image: $image,
    theme,
    title: $title,
    className
  } = props

  let imageData = $image

  if (theme === "modal-center") {
    imageData = image_modal
  }

  if (theme === "modal-bottom-left") {
    imageData = image_modal_small
  }

  const $modal = theme !== "bar-top"
  const $modalCentered = theme === "modal-center"

  let [modalIsOpen, setModalIsOpen] = useState($modal && true)

  const classes = classNames(content, {
    [themeOptions[theme]]: !$modal && themeOptions[theme]
  })

  const toggleModal = (isOpen) => {
    setModalIsOpen(!isOpen)
    lockScroll(!isOpen)
  }

  const lockScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.removeProperty("overflow")
    }
  }

  useEffect(() => {
    if ($modalCentered) {
      lockScroll(modalIsOpen)
    }
  }, [])

  const imageTag = (
    <div className={image}>
      <GatsbyImage alt={imageData?.title} image={getImage(imageData)} />
    </div>
  )

  const alertContents = (
    <div className={classes}>
      <div>
        {$modal && imageData && imageTag}
        {$modal && $title && <h4>{$title}</h4>}
        {$body && (
          <div className={body}>
            <p>{$body}</p>
          </div>
        )}
        {imageData && !$modal && imageTag}
        {$button && (
          <div className={button}>
            <Button {...$button} />
          </div>
        )}
      </div>
    </div>
  )

  let alertComponent = alertContents

  if ($modal) {
    const modalClasses = classNames(modal, {
      [className]: className,
      [themeOptions[theme]]: themeOptions[theme],
      [is_open]: modalIsOpen
    })

    alertComponent = (
      <div className={modalClasses}>
        <div className={modal_inner}>
          {theme !== "post-sticky-modal-alert" && theme !== "navigation" && (
            <button className={close} type="button" aria-label="Close" onClick={toggleModal}>
              <SVG name="close" />
            </button>
          )}
          {alertContents}
        </div>
      </div>
    )
  }

  return alertComponent
}

Alert.propTypes = {
  /**
   * Specifies the label and url of the Button CTA.
   */
  button: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  }),
  /**
   * Specifies the image
   */
  image: PropTypes.PropTypes.object,
  /**
   * Specifies the alert's theme
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),
  /**
   * Specifies the title
   */
  title: PropTypes.string
}

Alert.defaultProps = {
  button: []
}

export default Alert
